import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/app/node_modules/@interness/web-core/src/templates/mdx/default.js";
import { graphql } from 'gatsby';
import CallToAction from '@interness/theme-sonora/src/components/CallToAction/CallToAction';
import { RiTeamFill } from 'react-icons/ri';
import Team from '@interness/theme-sonora/src/components/Team';
export const query = graphql`
  query {
    headerImages: directusMediaCollection(name: {eq: "about-header"}) {
      name
      images: media {
        file {
          localFile {
            name
            childImageSharp {
              fluid(maxWidth: 1400, cropFocus: CENTER, quality: 100) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
    }
  }
`;
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const HeaderImage = makeShortcode("HeaderImage");
const Img = makeShortcode("Img");
const Wrapper = makeShortcode("Wrapper");
const Heading = makeShortcode("Heading");
const layoutProps = {
  query,
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <HeaderImage mdxType="HeaderImage">
  <Img fluid={props.data.headerImages.images[0].file.localFile.childImageSharp.fluid} alt='Über uns' mdxType="Img" />
    </HeaderImage>
    <Wrapper mdxType="Wrapper">
      <hr></hr>
  <Heading icon={<RiTeamFill mdxType="RiTeamFill" />} mdxType="Heading">Über uns</Heading>
      <p>{`  Wir kümmern uns um Ihre Anliegen - ob Uhren oder Schmuck, Reparaturen oder Anfertigungen, Trauringe oder
Verlobungsringe oder wenn Sie einfach nicht weiter wissen und Rat benötigen.
In Sachen Uhren und Schmuck sind wir die erste Wahl.`}</p>
      <hr></hr>
  <Team mdxType="Team" />
    </Wrapper>
    <hr></hr>
    <CallToAction mdxType="CallToAction" />


    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      